import * as React from "react";

import { Organization } from "./OrganizationModel";
import { TableBody, TableCell, TableRow, Table, TableHeader } from "@fluentui/react-components";
import { LABELS_I18N } from "../../common/i18n/pl_PL";

interface OrgTabProps {
  organization: Organization;
}

interface Item {
  key: string;
  value: string;
}
const itemTokens = [
  "name",
  "krs",
  "nip",
  "regon",
  "headquater",
  "address",
  "courtOfLaw",
  "wayOfRepresentation",
  "source",
];

const transformations = {
  address: (obj: any) => {
    return `${obj?.postalCode} ${obj?.city}, ${obj?.street ? obj?.street : obj?.city} ${obj?.streetNumber} ${
      obj?.apartment ? "lok. " + obj?.apartment : ""
    }`;
  },
  headquaterKRS: (obj: any) => {
    return `${obj?.voivodeship}, ${obj?.community}`;
  },
  headquaterCEIDG: (obj: any) => {
    return `${obj?.postalCode} ${obj?.city}, ${obj?.street ? obj?.street : obj?.city} ${obj?.streetNumber} ${
      obj?.apartment ? "lok. " + obj?.apartment : ""
    }`;
  },
  default: (str: string) => {
    return str;
  },
};

function transformToItems(organization: Organization): Item[] {
  let map = [];
  if (organization != null) {
    itemTokens.forEach((item) => {
      let t: { key: string; value: string };
      let strategy = item + organization.source;
      switch (item) {
        case "address":
          t = { key: LABELS_I18N.organizationData.rowLabels[item], value: transformations.address(organization[item]) };
          break;
        case "headquater":
          t = {
            key: LABELS_I18N.organizationData.rowLabels[item],
            value: transformations[strategy](organization[item]),
          };
          break;
        default:
          t = { key: LABELS_I18N.organizationData.rowLabels[item], value: transformations.default(organization[item]) };
          break;
      }
      if (!(t.value == null || typeof t.value == "undefined")) {
        map.push(t);
      }
    });
  }
  return map;
}

const OrganizationData: React.FC<OrgTabProps> = ({ organization }) => {
  const items = transformToItems(organization);
  return (
    <Table style={{ maxWidth: "100%" }}>
      <TableHeader />
      <TableBody>
        {items.map((item) => (
          <TableRow key={item.key}>
            <TableCell>{item.key}</TableCell>
            <TableCell>{item.value}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default OrganizationData;
