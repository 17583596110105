import * as React from "react";

import {
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableHeader,
  TableHeaderCell,
  Spinner,
} from "@fluentui/react-components";
import { LABELS_I18N } from "../../common/i18n/pl_PL";
import BeneficiaryAPI from "../api/BeneficiaryAPI";
import { useEffect } from "react";
import OrganizationError from "./OrganizationError";
import { AuthError } from "./OrganizationModel";

interface CRBRTabProps {
  id: string;
}

interface CRBRResponse {
  firstName: string;
  middleName?: string;
  lastName: string;
  pesel?: string;
  citizenship: string;
}

const transformations = {
  person: (obj: CRBRResponse) => {
    return `${obj?.firstName}${obj?.middleName ? " " + obj?.middleName : ""} ${obj?.lastName}`;
  },
  default: (str: string) => {
    return str;
  },
};

const CRBRData: React.FC<CRBRTabProps> = ({ id }) => {
  const [isLoading, setIsLoading] = React.useState<Boolean>(false);
  const [crbrData, setCrbrData] = React.useState<CRBRResponse[]>(null);
  const [errorMessage, setErrorMessage] = React.useState<AuthError>(null);

  useEffect(() => {
    setIsLoading(true);
    BeneficiaryAPI.getBeneficiaries(id)
      .then((response) => {
        return {
          data: response.json(),
          status: response.status,
        };
      })
      .then((res) => {
        if (res.status !== 200) {
          res.data.then((r) => {
            setErrorMessage({
              response: {
                status: res.status,
                data: {
                  code: r.code,
                  message: r.message,
                },
              },
            });
          });
          setCrbrData(null);
          setIsLoading(false);
        } else {
          res.data.then((data) => {
            setCrbrData(data);
            setIsLoading(false);
            setErrorMessage(null);
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setCrbrData(null);
        setErrorMessage({
          response: {
            status: 500,
            data: {
              code: 500,
              message: JSON.stringify(error),
            },
          },
        });
      });
  }, []);

  const columns = [
    { columnKey: "person", label: LABELS_I18N.CRBRData.rowLabels.person },
    { columnKey: "identification", label: LABELS_I18N.CRBRData.rowLabels.pesel },
    { columnKey: "citizenship", label: LABELS_I18N.CRBRData.rowLabels.citizenship },
  ];

  const items = crbrData;
  return (
    <React.Fragment>
      {isLoading && <Spinner />}
      {crbrData != null && errorMessage == null && (
        <Table style={{ maxWidth: "100%" }}>
          <TableHeader>
            <TableRow>
              {columns.map((column) => (
                <TableHeaderCell key={column.columnKey}>{column.label}</TableHeaderCell>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {items.map((item, idx) => (
              <TableRow key={idx}>
                <TableCell>{transformations.person(item)}</TableCell>
                <TableCell>{transformations.default(item.pesel)}</TableCell>
                <TableCell>{transformations.default(item.citizenship)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      {errorMessage != null && <OrganizationError error={errorMessage} />}
    </React.Fragment>
  );
};

export default CRBRData;
