/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, useState, useContext, FC, useEffect } from "react";
import axios, { AxiosRequestConfig } from "axios";
import { stringify } from "qs";
import { AuthenticationError } from "./ErrorModel";
import { jwtDecode } from "jwt-decode";
import "core-js/stable/atob";

export default interface AuthorizeResponse {
  access_token: string;
  expires_in: number;
  refresh_expires_in: number;
  refresh_token: string;
  token_type?: string;
  "not-before-policy"?: number;
  session_state?: string;
  scope?: string;
}

interface AuthorizationContextProps {
  token: string | null;
  fullName: string | null;
  userId: string | null;
  setToken: (token: string | null) => void;
  isAuthorized: boolean;
  login: (auth: AuthorizeResponse) => void;
  logout: () => void;
  refresh: () => void;
  isLoading: boolean;
  error: AuthenticationError | null;
  setError: (error: AuthenticationError | null) => void;
  isShowLogin: boolean;
  setIsShowLogin: (isShow: boolean) => void;
  isTokenValid: () => boolean;
  isRefreshTokenValid: () => boolean;
  isTokenNull: () => boolean;
}

export const AuthorizationContext = createContext<AuthorizationContextProps>({
  token: null,
  fullName: null,
  userId: null,
  setToken: () => {},
  isAuthorized: false,
  login: () => {},
  logout: () => {},
  refresh: () => {},
  isLoading: false,
  error: null,
  setError: () => {},
  isShowLogin: false,
  setIsShowLogin: () => {},
  isTokenValid: () => true,
  isRefreshTokenValid: () => true,
  isTokenNull: () => true,
});

export const AuthHttpSettings = {
  url: "https://iam.justblink.com.pl/auth/realms/justblink/protocol/openid-connect/token",
  clientId: "krs-add-in-app-v1",
};
export const LogoutSettings = {
  url: "https://iam.justblink.com.pl/auth/realms/justblink/protocol/openid-connect/logout",
};

type Props = {
  children?: React.ReactNode;
};

export const AuthorizationProvider: FC<Props> = ({ children }) => {
  // eslint-disable-next-line no-undef
  const [token, setTokenState] = useState<string | null>(localStorage.getItem("token") || null);
  // eslint-disable-next-line no-undef
  const [refreshToken, setRefreshTokenState] = useState<string | null>(localStorage.getItem("refresh_token") || null);
  // eslint-disable-next-line no-undef
  const [tokenExpire, setTokenExpireState] = useState<string | null>(localStorage.getItem("token_expire") || null);
  const [refreshTokenExpire, setRefreshTokenExpireState] = useState<string | null>(
    // eslint-disable-next-line no-undef
    localStorage.getItem("refresh_token_expire") || null
  );

  const [error, setError] = useState<{ error_description: string } | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
  const [isShowLogin, setIsShowLogin] = useState<boolean>(false);
  const [fullName, setFullName] = useState<string>(null);
  const [userId, setUserId] = useState<string>(null);

  const AuthHttpHeaders: AxiosRequestConfig = {
    headers: {
      accept: "*",
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": "*",
    },
  };

  useEffect(() => {
    let timeout = +tokenExpire - new Date().getTime() - 1000;
    if (isAuthorized == false && token != null && refreshToken != null) {
      refresh();
    } else {
      // eslint-disable-next-line no-undef
      const timer = setTimeout(() => {
        refresh();
      }, timeout);

      return () => {
        // eslint-disable-next-line no-undef
        clearTimeout(timer);
      };
    }
    return () => {};
  }, [tokenExpire]);

  const getLogoutParams = (): string => {
    return stringify({
      client_id: AuthHttpSettings.clientId,
      // eslint-disable-next-line no-undef
      refresh_token: localStorage.getItem("refresh_token"),
    });
  };

  const getRefreshParams = (): string => {
    return stringify({
      grant_type: "refresh_token",
      // eslint-disable-next-line no-undef
      refresh_token: localStorage.getItem("refresh_token"),
      client_id: AuthHttpSettings.clientId,
    });
  };

  const setToken = (token: string | null) => {
    setTokenState(token);
    if (!token) {
      // eslint-disable-next-line no-undef
      localStorage.removeItem("token");
    } else {
      // eslint-disable-next-line no-undef
      localStorage.setItem("token", token);
    }
  };

  const setRefreshToken = (token: string | null) => {
    setRefreshTokenState(token);
    if (!token) {
      // eslint-disable-next-line no-undef
      localStorage.removeItem("refresh_token");
    } else {
      // eslint-disable-next-line no-undef
      localStorage.setItem("refresh_token", token);
    }
  };

  const setTokenExpire = (time: string | null) => {
    setTokenExpireState(time);
    if (!time) {
      // eslint-disable-next-line no-undef
      localStorage.removeItem("token_expire");
    } else {
      // eslint-disable-next-line no-undef
      localStorage.setItem("token_expire", time);
    }
  };

  const setRefreshTokenExpire = (time: string | null) => {
    setRefreshTokenExpireState(time);
    if (!time) {
      // eslint-disable-next-line no-undef
      localStorage.removeItem("refresh_token_expire");
    } else {
      // eslint-disable-next-line no-undef
      localStorage.setItem("refresh_token_expire", time);
    }
  };

  const isTokenValid = (): boolean => {
    const now = new Date().getTime();

    // eslint-disable-next-line no-undef
    return now < Number(localStorage.getItem("token_expire"));
  };

  const isTokenNull = (): boolean => {
    // eslint-disable-next-line no-undef
    return localStorage.getItem("token") == null;
  };

  const isRefreshTokenValid = (): boolean => {
    const now = new Date().getTime();
    // eslint-disable-next-line no-undef
    return now < Number(localStorage.getItem("refresh_token_expire"));
  };

  function parseAccessToken(access_token: string): any {
    return jwtDecode(access_token);
  }

  const login = async (authorizeResponse?: AuthorizeResponse, error?: AuthenticationError) => {
    let parsedToken = parseAccessToken(authorizeResponse.access_token);
    if (error) {
      setError(error);
      setIsLoading(false);
    } else {
      const currentDate = new Date().getTime();
      setFullName(parsedToken.name);
      setUserId(parsedToken.sub);
      setError(null);
      setToken(authorizeResponse.access_token);
      setRefreshToken(authorizeResponse.refresh_token);
      setIsAuthorized(true);
      setIsShowLogin(false);
      setTokenExpire(authorizeResponse.expires_in.toString());
      const refreshExpireTime = currentDate + authorizeResponse.refresh_expires_in * 1000;
      setRefreshTokenExpire(refreshExpireTime.toString());
      setIsLoading(false);
    }
    return;
  };

  const logout = async () => {
    setError(null);
    setIsLoading(true);
    await axios
      .post(LogoutSettings.url, getLogoutParams(), AuthHttpHeaders)
      .then((result) => {
        if (result.status !== 200) {
          setError({ error_description: "Logout unknown error" });
        }
      })
      .catch(() => {
        setError({ error_description: "Logout failed." });
      })
      .finally(() => {
        setIsLoading(false);
        setIsAuthorized(false);
        // eslint-disable-next-line no-undef
        localStorage.clear();
      });
  };

  const refresh = async () => {
    setIsLoading(true);
    // @ts-ignore
    await axios
      .post(AuthHttpSettings.url, getRefreshParams(), AuthHttpHeaders)
      .then((result) => {
        if (result.status === 200) {
          const authorizeResponse = result.data as AuthorizeResponse;
          let parsedToken = parseAccessToken(authorizeResponse.access_token);
          setFullName(parsedToken.name);
          setUserId(parsedToken.sub);
          setToken(authorizeResponse.access_token);
          setIsShowLogin(false);
          setError(null);
          setRefreshToken(authorizeResponse.refresh_token);
          const expireTime = new Date().getTime() + authorizeResponse.expires_in * 1000;
          setTokenExpire(expireTime.toString());
          setIsAuthorized(true);
        } else {
          setError({ error_description: "Unknown error" });
          setIsAuthorized(false);
        }
      })
      .catch((e) => {
        setError(e.response.data);
        setIsAuthorized(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <AuthorizationContext.Provider
      value={{
        token,
        fullName,
        userId,
        setToken,
        isAuthorized,
        login,
        logout,
        refresh,
        isLoading,
        error,
        setError,
        isShowLogin,
        setIsShowLogin,
        isTokenValid,
        isRefreshTokenValid,
        isTokenNull,
      }}
    >
      {children}
    </AuthorizationContext.Provider>
  );
};

export const useAuthorization = (): AuthorizationContextProps => useContext(AuthorizationContext);
