const BeneficiaryAPI = {
  baseURL: "https://api.justblink.com.pl",
  //baseURL: "http://localhost:3002",

  getBeneficiaries: (id: string): Promise<Response> => {
    // eslint-disable-next-line no-undef
    const token = localStorage.getItem("token");

    // eslint-disable-next-line no-undef
    return fetch(`${BeneficiaryAPI.baseURL}/organisations/${id}/real-beneficiaries`, {
      headers: {
        Accept: "application/json; charset=utf-8",
        Authorization: `Bearer ${token}`,
      },
      method: "GET",
    });
  },
};

export default BeneficiaryAPI;
